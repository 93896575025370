<!-- 도약 전체 공통 레이아웃  -->

<template>
  <VApp class="relative" :class="(isHuman ? 'isHuman' : 'isBot')" :theme="isDark ? 'dark' : 'light'">

    <DYRightPanel v-if="isHuman" v-model:selectedMenu="rightPanelSelected">
      <!-- 이 라이트 패널 안의 내용들은 -->
      <slot name="rightPanelImpl" :selectedMenu="rightPanelSelected"></slot>
    </DYRightPanel>

    <VMain>
      <ClientOnly v-if="isHuman">
        <!-- 'VOverlay'로 감싸면 페이지 이동 후 스크롤 이슈가 생김!! 스크롤바가 사라지지 않은 상태로 로딩 UI가 나타나야 문제가 생기지 않으니 주의할 것! -->
        <div v-if="mainStore.isLoading"
          class="h-screen w-screen fixed bg-zinc-950 bg-opacity-50 z-[5000] flex items-center justify-center"
          @click="mainStore.isLoading = false">
          <MiscDYLoading />
        </div>
      </ClientOnly>
      <div v-if="isHuman">
        <slot name="loading">
          <DYLandingScreen />
        </slot>
      </div>
      <template v-if="!isHuman || !mainStore.isLanding">
        <!-- <div v-if="isHuman && isAdmin" class="alert alert-warning text-center m-0" role="alert"
          style="color:#c18300;background-color:#fff8e9;border-color:#ffcd65; font-size: 14px; border-bottom: 1px solid black;">
          <strong>관리자 모드</strong><br>
          일반: {{ `${fullDomain}/v${route.fullPath}` }}<br>
          앱 링크: {{ `${fullDomain}/v${appLinkUrl}` }}
        </div> -->

        <!-- 여기에 텔레포트가 없으면 오버레이가 걸린 상황에서 알림이 백그라운드 뒤쪽에서 뜨게됨 -->
        <Teleport to="body">
          <notifications></notifications>

          <notifications group="login">
            <template #body="props">
              <div class="my-notification" @click="props.close"
                style="margin: 0 5px 5px;  padding: 10px;  font-size: 12px;  color: #ffffff; background: #ffb648; border-left-color: #f48a06;">
                <p class="title text-base">
                  {{ props.item.title }}
                </p>
                <div class="text-base" v-html="props.item.text" />

                <div class="flex justify-end mt-2">
                  <v-btn color="white" @click="needLogin">로그인</v-btn>
                </div>
              </div>
            </template>
          </notifications>

          <notifications group="couponAlreadyRegist">
            <template #body="props">
              <div class="my-notification" @click="props.close"
                style="margin: 0 5px 5px;  padding: 10px;  font-size: 12px;  color: #ffffff; background: #ffb648; border-left-color: #f48a06;">
                <p class="title text-base">
                  {{ props.item.title }}
                </p>
                <div class="text-base" v-html="props.item.text" />
                <div class="text-base">1+1 이벤트 적용방법은 아래 버튼을 클릭하세요.</div>
                <div class="flex justify-end mt-2">
                  <v-btn color="white" to="/cs/faq/site">1+1 이벤트 이용하는 방법</v-btn>
                </div>
              </div>
            </template>
          </notifications>

          <notifications group="bottom-center">
            <template #body="props">
              <div class="fixed left-0 bottom-5 flex justify-center w-full">
                <div class=" bg-green-500" @click="props.close"
                  style="margin: 0 5px 5px;  padding: 10px;  font-size: 12px;  color: #ffffff; border-left-color: #f48a06;">
                  <p class="title text-base">
                    {{ props.item.title }}
                  </p>
                  <div class="text-base" v-html="props.item.text" />
                </div>
              </div>
            </template>
          </notifications>
        </Teleport>

        <ClientOnly>
          <template v-if="isHuman && layoutStore.isTopBannerVisible && !layoutStore.isIframe">
            <BannerDYDefaultCampaign
              v-if="windowStore.isDesktop && (closeTopBanner || !layoutStore.isTopBannerVisible)" />
            <BannerDYTopBanner v-else-if="windowStore.windowWidth > 768" @close-top-banner="closeTopBanner = true" />
            <BannerDYPopUpBanner v-if="bannerStore.bannerData && bannerStore.bannerData.popup && route.path === '/'"
              :popup-banner="bannerStore.bannerData.popup"></BannerDYPopUpBanner>
            <!-- <BannerDYTopBannerNew v-else-if="windowStore.windowWidth > 768" @close-top-banner="closeTopBanner = true" />
            <BannerDYPopUpBannerNew v-if="bannerStore.bannerData && bannerStore.bannerData.popup && route.path === '/'"
            :popup-banner="bannerStore.bannerData"></BannerDYPopUpBannerNew> -->
          </template>

          <BannerDYRecentChecked v-if="visibleBannerRecentChecked" class="fixed w-full z-10"></BannerDYRecentChecked>
        </ClientOnly>


        <!-- 로그인 id : {{ id }} -->
        <div>
          <slot name="topLine" v-if="visibleTopLine"></slot>
          <slot v-if="visibleActionbar" name="actionbar">

          </slot>

          <ClientOnly v-if="isHuman">
            <DYAppLinkNotice v-if="hasAppLink"></DYAppLinkNotice>
            <slot name="channelTalk"></slot>
          </ClientOnly>

          <!-- 액션바 탑메뉴는 기본적으로는 공통이나, 마퍼가는 전용 코드를 쓸 것임 -->
          <slot name="actionbarTopMenu" v-if="visibleActionbarTopMenu">
            <DYTopMenuSwipe v-if="availableTopMenuSwipe && layoutStore.isActionBarVisible && !layoutStore.isIframe">
            </DYTopMenuSwipe>
          </slot>
        </div>
        <div>
          <!-- <KeepAlive> -->
          <!-- <DYErrorPage v-if="mainStore.error" :error="mainStore.error" /> -->
          <slot></slot>
          <!-- </KeepAlive> -->

          <ClientOnly>
            <slot name="floatingButtons" v-if="isHuman">
              <MiscDYFloatingButtons v-if="layoutStore.isFloatingButtonsVisible" />
            </slot>
            <DYInstantMessage v-if="isHuman && userStore.isSignedIn && !layoutStore.isIframe"></DYInstantMessage>
          </ClientOnly>
        </div>

        <div>
          <slot v-if="visibleFooter" name="footer">
            <DYFooter />
          </slot>
        </div>

      </template>

      <!-- dom구조로 바로 접근해서 hidden을 컨트롤하기 위함. print()등에서 사용위함 -->
      <div class="hidden"></div>
    </VMain>
  </VApp>
</template>

<script setup lang="ts">
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHammer,
  faCircle as farCircle,
  faDotCircle as farDotCircle,
  faSquare as farSquare,
  faStar as farStar
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowDown as fasArrowDown,
  faArrowUp as fasArrowUp,
  faArrowsAltV as fasArrowsAltV,
  faBars as fasBars,
  faCalendar as fasCalendar,
  faCaretDown as fasCaretDown,
  faCheck as fasCheck,
  faCheckCircle as fasCheckCircle,
  faCheckSquare as fasCheckSquare,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faChevronUp as fasChevronUp,
  faCircle as fasCircle,
  faEdit as fasEdit,
  faExclamation as fasExclamation,
  faExclamationTriangle as fasExclamationTriangle,
  faInfoCircle as fasInfoCircle,
  faMinus as fasMinus,
  faMinusSquare as fasMinusSquare,
  faPaperclip as fasPaperclip,
  faPlus as fasPlus,
  faStar as fasStar,
  faStarHalf as fasStarHalf,
  faStepBackward as fasStepBackward,
  faStepForward as fasStepForward,
  faSync as fasSync,
  faTimes as fasTimes,
  faTimesCircle as fasTimesCircle
} from '@fortawesome/pro-solid-svg-icons';
import * as Sentry from '@sentry/vue';
import { H3Error } from 'h3';
import { useDisplay } from 'vuetify';
import { useDark, useToggle } from '@vueuse/core'
import { useDYUserStore } from '~/modules/nuxt3-module-doyac/stores/user';
import { ForbiddenError, UnauthorizedError, useDYAuth } from '~/modules/nuxt3-module-doyac/composables/auth';
import { useDYAppConfig } from '~/modules/nuxt3-module-doyac/composables/config';
import { useDYBanner } from '~/modules/nuxt3-module-doyac/composables/dy-banner';
import { DYServerMiddlewareManager } from '~/modules/nuxt3-module-doyac/composables/dy-server-middleware';
import { extractDataOrFailure, useDYFetch, useDYFetchWithToken } from '~/modules/nuxt3-module-doyac/composables/fetch';
import { useUtils } from '~/modules/nuxt3-module-doyac/composables/utils';
import { useDYBannerStore } from '~/modules/nuxt3-module-doyac/stores/banner';
import { useDYErrorStore } from '~/modules/nuxt3-module-doyac/stores/error';
import { useDYLayoutStore } from '~/modules/nuxt3-module-doyac/stores/layout';
import { useDYMainStore } from '~/modules/nuxt3-module-doyac/stores/main';
import { useDYWindowStore } from '~/modules/nuxt3-module-doyac/stores/window';
import { useDYDefault } from "~/modules/nuxt3-module-doyac/composables/dy-default";
import { useYouTubeUtility } from '~/modules/nuxt3-module-doyac/utils/YouTubeUtility';

// Vuetify가 각종 컴퍼넌트에서 기본적으로 사용하는 아이콘들. 여기서 추가된 아이콘은 다른 곳에서는 별도로 추가할 필요가 없다.
// 자세한 아이콘 목록은 vuetify\lib\iconsets\fa.mjs 파일 참고.
library.add(
  // fas
  fasChevronUp, fasCheck, fasTimesCircle, fasTimes, fasCheckCircle, fasInfoCircle, fasExclamation,
  fasExclamationTriangle, fasChevronLeft, fasChevronRight, fasCheckSquare, fasMinusSquare, fasCircle,
  fasArrowUp, fasArrowDown, fasChevronDown, fasBars, fasCaretDown, fasEdit, fasStar, fasStarHalf,
  fasSync, fasStepBackward, fasStepForward, fasArrowsAltV, fasPaperclip, fasPlus, fasMinus, fasCalendar,
  // far
  farSquare, farDotCircle, farCircle, farStar, faHammer
)

interface IOSNavigationInfoEvent extends Event {
  detail: {
    canGoBack: boolean
    canGoForward: boolean
  }
}

const route = useRoute()
const router = useRouter()
const mainStore = useDYMainStore()
const windowStore = useDYWindowStore()
const errorStore = useDYErrorStore()
const { isHuman, ready, isAndroidOS, isAndroidApp } = useDYUserStore()
const userStore = useDYUserStore()
const layoutStore = useDYLayoutStore()
const { hasAuth, needLogin } = useDYAuth()
const { checkIp, makeSSRStatisticsData, sendSSRStatistics } = DYServerMiddlewareManager()
const { fullDomain, siteName, GTAG_SEND_TO, NAVER_ANALYTICS_ID, domain, fullUrl, jsonLd, basePath } = useDYAppConfig()
const { initBanner } = useDYBanner()
const bannerStore = useDYBannerStore()
const display = useDisplay()
const { notifyError } = useUtils()
const { syncLegacy } = useDYDefault()
const giid = useState<string | undefined>()


const isDark = useDark()
const toggleDark = useToggle(isDark)
// 일반 유저는 다크모드 아직 지원하지 않음. 다른 컴퍼넌트들이 다크 모드를 제대로 지원하기 전까지는 사용할 수 없음
isDark.value = false

const { invokeFunctionToAll } = useYouTubeUtility()
const { gtag } = useGtag()

const rightPanelSelected = ref(1)

const availableTopMenuSwipe = computed(() => {
  if (route.path === '/moviePlayer' || route.path === '/tourPlayer' || route.path === '/pdfjs' || route.path.includes('/cs')) return false
  return windowStore.windowWidth <= 1200 && !layoutStore.isActionBarTransparent
})
const isAdmin = computed(() => hasAuth('admin'))

//서버 사이드에서 해야할 일들
if (process.server) {
  await checkIp()
  await makeSSRStatisticsData()

  const nuxtApp = useNuxtApp()

  const headers = nuxtApp.ssrContext?.event.headers
  giid.value = headers?.get('dy-google-instance-id') ?? undefined

  if (nuxtApp.ssrContext?.event.context.body) {
    if (['/login', '/join/step1', '/mypage/delmember', '/mypage/connectSocialLogin'].includes(route.path)) {
      if (nuxtApp.ssrContext.event.context.body instanceof FormData) {
        const appleLoginData = {
          state: nuxtApp.ssrContext.event.context.body.get('state'),
          code: nuxtApp.ssrContext.event.context.body.get('code'),
          id_token: nuxtApp.ssrContext.event.context.body.get('id_token')
        }
        mainStore.bodyData = appleLoginData
      }
    }
  }
}

const ogTitle = computed(() => {
  if (siteName === '도약닷컴') return '음악 동영상교육 도약닷컴'
  if (siteName === '도약아트') return '미술 동영상교육 도약아트'
  if (siteName === '마이퍼스트가이드') return '나만의 스마트 투어 마이퍼스트가이드'
  return siteName
})

const description = computed(() => {
  if (siteName === '도약닷컴') return '음악이론, 기타, 피아노, 보컬, 랩, 미디작곡, 색소폰, 드럼, 국악, 현악기 등. 제대로 배워보고 싶다면 도약닷컴에서 온라인 클래스를 만나보세요!'
  if (siteName === '도약아트') return '소묘, 수채화, 유화, 동양화, 캘리그라피, 사진, 디자인, 공예, 의류제작까지. 제대로 배워보고 싶다면 도약아트에서 온라인 클래스를 만나보세요!'
  if (siteName === '마이퍼스트가이드') return '가이드와 함께하는 현지투어를 온라인으로 만나보세요! 저렴하고 정확한 정보를 제공하면서도 정말 편리합니다. 가이드는 마이퍼스트가이드'
  if (siteName === '레슨올') {
    return '음악, 미술, 디자인, 체육, 무용, 컴퓨터 까지! 레슨올에서 내가 찾는 전문가를 만나보세요.'
  }

  return ''
})

const visibleTopLine = computed(() => !route.query.noactionbar && !route.query.onlyCaptureContent && !route.query.onlyCaptureRoute)
const visibleActionbar = computed(() => layoutStore.isActionBarVisible && !layoutStore.isIframe && !route.query.noactionbar && !route.query.onlyCaptureContent && !route.query.onlyCaptureRoute)
const visibleBannerRecentChecked = computed(() => isHuman && windowStore.windowWidth > 1500 && layoutStore.isRecentlyViewGoodsVisible && !layoutStore.isIframe && !route.query.noactionbar && !route.query.onlyCaptureContent && !route.query.onlyCaptureRoute)
const visibleActionbarTopMenu = computed(() => !route.query.noactionbar && !route.query.onlyCaptureContent && !route.query.onlyCaptureRoute)
const visibleFooter = computed(() => isHuman && layoutStore.isFooterVisible && !layoutStore.isIframe && !route.query.noactionbar && !route.query.onlyCaptureContent && !route.query.onlyCaptureRoute)




const ogImage = (() => {
  if (siteName === '레슨올') {
    return fullDomain + '/m/images/app_icon.png'
  }

  return fullDomain + '/m/images/app_icon.png'
})()


const queryString = route.query ? route.query : {};
/*const filteredQuery = omit(queryString, ["__e", "a"])
//const queryStr = '?' + new URLSearchParams(filteredQuery).toString();*/

const queryStr = new URLSearchParams(queryString as Record<string, string>);
let makeQuery = ""
const qsForFilters = [
  undefined, null, 'undefined', 'null', 'news_letter', '__e',
  'utm_campaign', 'utm_medium', 'utm_source', 'utm_term', 'utm_content',
  'n_media', 'n_keyword', 'n_query', 'n_ad_group', 'n_rank', 'n_ad', 'n_keyword_id', 'n_campaign_type', 'n_ad_group_type', 'n_match',
  'NaPm', 'DMKW', 'DMSKW', 'DMCOL', 'an', 'ct', 'referer', 'languageCode', 'desktopMode'
]

for (const [key, value] of queryStr.entries()) {
  if (!value || qsForFilters.includes(key)) {
    continue
  }
  if (makeQuery == "") makeQuery += "?"
  else makeQuery += "&"
  makeQuery += key + "=" + value
}
const initFirstUrl = fullUrl + route.path.replace(/akbomr/g, 'akboMr') + makeQuery

useSeoMeta({
  title: siteName,
  description: description.value,
  ogTitle: ogTitle.value,
  ogDescription: description.value,
  ogImage: ogImage,
  ogType: 'website',
  ogUrl: initFirstUrl,
  ogSiteName: siteName,
  ogLocale: 'ko_KR'
  // author
})

useHead({
  bodyAttrs: {
    class: (userStore.isIOS ? 'isIOS' : '') + (userStore.isMacOS ? ' isMacOS' : '')
  },
  link: [
    { rel: 'canonical', href: initFirstUrl }
  ],
  script: [
    {
      src: (userStore.isHuman) ? '//wcs.naver.net/wcslog.js' : undefined
    },
    {
      src: `${basePath}/polyfill-Array.at.js`
    },
  ]
})

useJsonld(jsonLd)

//---------------데이터

const closeTopBanner = ref(false)
const hasAppLink = ref(false)
const mounted = ref(false)

// 우하단 CS 아이프레임 관련 커스텀 
const bottomSheetIframeLoaded = ref(false)
const bottomSheetToGoOutLink = ref(false)
const bottomSheetRefresh = ref(false)

//---------------컴퓨티드

//---------------메서드

const iOSNavigationInfoEventCallback = (event: Event) => {
  const _event = event as IOSNavigationInfoEvent
  windowStore.canGoBack = _event.detail.canGoBack
  windowStore.canGoForward = _event.detail.canGoForward
}

const unwatch = watch(() => route.fullPath, (to, from) => {
  if (from) userStore.isFirstPage = false
  unwatch()
})

const adjustDesktopModeAppleDevice = () => {
  //deprecated될 예정이긴 한데, 이 대체로 쓸 수 있는 userAgentData가 아직 지원되지 않는 브라우저가 많아서 우선 이걸로 씀. 23-04-12
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  let platform = navigator?.userAgentData?.platform || navigator?.platform || undefined

  if (!userStore.isIOS && userStore.isMacOS && platform) { // iOS 사파리에서 강제 데스크탑 모드로 인식시킨 경우 Mac OS 인식되는 문제가 있으므로 아래 코드를 통해 보정함
    const ios = /iPad|iPhone|iPod/.test(platform) || (platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    userStore.isIOS = ios
    userStore.isMacOS = !ios
  } else if (userStore.isIOS && userStore.isMacOS) {
    userStore.isMacOS = false
  }
}

const inAppRequest = () => {
  userStore.inAppLoading = false
}

const loginAfterProcess = async () => {
  syncLegacy() //구 페이지와의 세션연동

  if (userStore.isSignedIn) {
    try {
      const response = await useDYFetchWithToken<Record<string, string>>('/mer/api/payments/typed-pks')
      const data = extractDataOrFailure(response)

      userStore.myPayment = data
    } catch (e: any) {
      console.error('DYDefault1', e)
      notifyError(e)
    }
  }
}

const scrollEvent = () => {
  if (window.scrollY > 800) {
    window.removeEventListener('scroll', scrollEvent, false)
    gtag('event', 'conversion', { send_to: GTAG_SEND_TO })
  }
}

// const setupNaverAnalytics = () => {
//   if (!window.wcs_add) { window.wcs_add = {} }
//   window.wcs_add.wa = `${NAVER_ANALYTICS_ID}`
//
//   if (!window._nasa) { window._nasa = {} }
//
//   if (window.wcs && window.wcs_do) {
//     window.wcs?.inflow(`${domain}`)
//     window.wcs_do(window._nasa)
//   }
// }

const visibilityChange = () => {
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      if (userStore.accessToken && !userStore.accessTokenExpiration) {
        location.reload()
      }
      if (userStore.accessToken && userStore.accessTokenExpiration && userStore.accessTokenExpiration.getTime() < new Date().getTime()) {
        location.reload()
      }
    }
  })
}



//---------------훅

// 초기 배너 데이터를 세팅함
initBanner()

onBeforeMount(() => {
  adjustDesktopModeAppleDevice()

  if (process.client && userStore.isSignedIn) {
    loginAfterProcess()
  }
})

if (process.client) {
  watch(() => route.path, (val) => {
    userStore.isFirstPage = false

    if (val) {
      try {
        //방문한 페이지를 기록
        useDYFetch<any>('/mer/api/statistics/page', { method: 'put', body: { url: route.fullPath } })
      } catch (e) {

      }
    }

    // iOS 앱에서 앞/뒤 페이지 이동이 가능한지 판단하기 위한 코드
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.scriptHandler) {
      window.webkit.messageHandlers.scriptHandler?.postMessage({ script: 'requestNavigationInfo' })
    }
  })

  watch(() => userStore.isSignedIn, (val) => { //유저의 로그인을 감지해서 처리
    if (val) { //로그인한 경우
      loginAfterProcess()
    }
  })

  watch(() => mainStore.isLoading, val => {
    if (!process.client) {
      return
    }

    // 로딩 중일 때(정확하게는 페이지가 이동 중일 때) 스크롤을 허용하면 이동 전의 페이지가 변화가 생길 수 있어서
    // VImg에서 에러가 날 수 있음. 따라서 로딩 중일 때는 스크롤되지 못하게 막아야함.
    const html = document.getElementsByTagName('html')[0]
    html.style.overflow = val ? 'hidden' : 'auto'
  })
}

onMounted(async () => {
  await nextTick()

  window.addEventListener('scroll', scrollEvent, false) //gtag관련 스크롤 이벤트
  window.addEventListener('InAppRequest', inAppRequest) //인앱 요청시 로딩관련

  //배포 버전과 똑같이 테일윈드 CSS의 우선도를 가장 높이기 위함
  let elements = document.querySelectorAll('style')
  for (let elem of elements) {
    if (elem instanceof HTMLStyleElement && elem.innerText.includes('tailwindcss')) {
      document.getElementsByTagName('body')[0].prepend(elem)
    }
  }

  // 네이버 수집 가능
  // setupNaverAnalytics()

  await ready()

  // 로그인 요청 끝나면
  mainStore.isLoading = false

  const supportsContainerQueries = "container" in document.documentElement.style;
  if (!supportsContainerQueries) {
    //@ts-ignore
    import("container-query-polyfill");
  }

  if (process.client) {
    // 인젝트 배너 데이터.. 이런게 필요한가? 일단 구현안함

    visibilityChange() //토큰 만료된 사람인 경우 새로 고침해서 내용 갱신

    // 안드로이드 앱의 경우 웹뷰 액티비티가 paused 되면 `window.invokeYouTubeFunction` 메서드를 호출하므로 반드시 주입해줘야한다.
    if (window.android && userStore.isAndroidApp) {
      window.invokeYouTubeFunction = invokeFunctionToAll
    }

    nextTick(() => {
      // 원래대로라면 안드로이드 앱에서 접근하는 경우 요청 헤더에 `x-requested-with`가 앱의 패키지명으로 들어와야 하는데
      // 간혹 들어오지 않는 현상이 보고되고 있음. 이를 해결하기 위한 코드로 안드로이드 앱용 자바스크립트 메서드가 있다고
      // 판단되면 앱으로 강제 인식시킨다.
      if (!userStore.isAndroidApp && window.android?.getAppVersion) {
        userStore.isAndroidApp = true
      }

      if (userStore.isSignedIn && giid.value) {
        try {
          useDYFetchWithToken('/mer/api/google-instance-id', {
            method: 'patch',
            headers: {
              'dy-google-instance-id': giid.value
            }
          }).then(() => {
            // 아무 것도 할 필요 없음
          })
        } catch (e: any) {
          console.error('DYDefault3', e)
          notifyError(e)
        }
      }

      userStore.ssrStatisticsData.ip = userStore.ip
      sendSSRStatistics()

      if (route.query.appLink && route.query.appLink === '1' && isAndroidOS && !isAndroidApp) { //모바일 기긴데 웹으로 온 경우 앱 유도
        hasAppLink.value = true
      }

      window.addEventListener('iOSNavigationInfoEvent', iOSNavigationInfoEventCallback)
    })
  }

  if (layoutStore.isIframe) window.parent.postMessage('onload', '*')

  mounted.value = true
})

onUnmounted(() => {
  window.removeEventListener('iOSNavigationInfoEvent', iOSNavigationInfoEventCallback)
})

onErrorCaptured((e: any) => {
  console.error('ERROR CAPTURED!!!!!!', e)

  // 핸들링되지 않은 Nuxt의 fetch 메서드에 의해 발생한 에러인 경우. 보통 필수가 아닌 상황이므로 별도의 에러 처리를 하지
  // 않는다.
  if (e instanceof H3Error) {
    console.error('ERROR CAPTURED??????', e.name, e.message, e.toString())
  } else if (e instanceof UnauthorizedError) {
    console.error('UnauthorizedError!!!')
    router.replace(e.loginUrl)
  } else if (e instanceof ForbiddenError) {
    console.error('ForbiddenError!!!')
    errorStore.lastGlobalError = { statusCode: 403 }
    router.replace('/error')
  } else {
    // 여기로 온 에러는 핸들링이 된 에러로 간주되어 센트리에 자동 보고 되지 않으므로 직접 센트리에 보고하게 해야함!!
    if (process.client) {
      Sentry.captureException(e)
    }

    const { ssrContext } = useNuxtApp()

    const params = {
      logOnly: true,
      code: e.statusCode ?? 500,
      REQUESTURI: fullUrl + route.fullPath,
      referer: ssrContext?.event.node.req.headers.from,
      errorType: ssrContext?.event.node.req.headers.from ? 'spa' : 'ssr',
      message: `${e instanceof Error ? e.stack : e}`
    }

    // showError(e) // `showError`는 에러난 상태를 그대로 캐싱하므로 다시 접근할 때 문제가 생긴다. 사용금지!
    errorStore.lastGlobalError = e
    router.replace('/error')
  }
})

</script>
