<template>
  <div class="vuetify3">
    <v-navigation-drawer v-if="layoutStore.isRightPannelVisible" v-model="open" location="right" :temporary="true"
      width="300">
      <div v-show="open" class="contents h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
        <div>
          <nav id="openPanel" class="gnb hamburger is-open" style="padding-bottom:0px;">
            <div class="top_btn flex justify-between">
              <li class="login min-w-[150px] flex-grow" @click="hamburger_cross">
                <span v-if="!userStore.isSignedIn">
                  <DYLoginLink class="btn_login" role="button">로그인</DYLoginLink>
                </span>
                <span v-else>
                  <MiscDYLink to="/mypage" style="width:100%">
                    <div class="sc_psn_stt">
                      <span class="sc_psn_a">
                        <DYImg v-if="userStore.pictureSet" :src="userStore.pictureSet.thumbnail_s" cover
                          :srcset="userStore.pictureSet.dataSet" width="36px" height="36px"
                          style="display: inline-block;" />
                        <span class="userNickName bottom-3 absolute">{{ userStore.nickname ? userStore.nickname :
                          userStore.name
                          }} 님</span>
                      </span>
                    </div>
                  </MiscDYLink>
                </span>
              </li>
              <div class="min-w-[110px]">
                <li v-if="siteName !== '레슨올'" class="search" @click="hamburger_cross">
                  <MiscDYLink v-if="!userStore.isAndroidApp" to="/search" class="btn_search !mt-0" role="button">
                    <v-icon icon="far fa-search-plus" />
                  </MiscDYLink>
                  <a v-else href="#" class="btn_search !mt-0" role="button" @click="openAndroidAppSetting">
                    <v-icon icon="fal fa-cog" />
                  </a>
                </li>
                <li class="mailbox" @click="hamburger_cross">
                  <MiscDYLink
                    :href="siteName === '레슨올' ? 'https://www.lessonall.com/mer/milk/messageBox?perPage=5&mode=all&checkOpenMessage=true' : ''"
                    :to="siteName === '레슨올' ? '' : '/mypage/messageBox'" class="btn_mailbox !mt-0" role="button">
                    <v-icon icon="fal fa-mail-bulk" />
                    <v-chip size="x-small" class="badge badge-icon text-red-500 absolute text-sm top-0 right-10">
                      {{ userStore.countUnreadMessage }}
                    </v-chip>
                    <!-- <span v-if="unreadMessage" class="badge badge-icon text-red-500 absolute text-sm top-0">
                    {{ unreadMessage }}
                  </span> -->
                  </MiscDYLink>
                </li>
                <li>
                  <a class="btn_close toggleHamburger ml-2" role="button" @click="hamburger_cross"
                    style="position:relative; top:-15px;">
                    <v-icon icon="fal fa-times" />
                  </a>
                </li>
              </div>
            </div>
            <div class="scroll_box firstGnbArea">
              <!-- 1Depth 메뉴트리 -->
              <ol class="depth_1_menu">
                <li>
                  <a class="depth_1" @click="toHome" title="홈으로">홈으로</a>
                </li>
                <!-- 인강 버전 트리 -->
                <template v-if="siteName !== '레슨올'">
                  <li @click="selectMenu(1)" :class="selectedMenu === 1 || !selectedMenu ? 'active' : ''">
                    <a class="depth_1" data-name="menu01" href="javascript:void(0);" title="전체 콘텐츠">
                      {{ siteName === "마이퍼스트가이드" ? "전체 투어" : "전체 클래스" }}
                    </a>
                  </li>
                  <li v-if="siteName === '도약닷컴' || siteName === '도약아트'" @click="selectMenu(2)"
                    :class="selectedMenu === 2 ? 'active' : ''">
                    <a class="depth_1" data-name="menu02" href="javascript:void(0);"
                      :title="siteName === '도약닷컴' ? '북스토어' : '도약화방'">{{ siteName === '도약닷컴' ? '북스토어' : '도약화방'
                      }}</a>
                  </li>
                  <li v-if="siteName === '도약닷컴'" @click="selectMenu(3)" :class="selectedMenu === 3 ? 'active' : ''">
                    <a class="depth_1" data-name="menu03" href="javascript:void(0);" title="악보/MR샵">악보/MR샵</a>
                  </li>
                  <li v-if="siteName === '마이퍼스트가이드'" @click="selectMenu(7)"
                    :class="selectedMenu === 7 ? 'active' : ''">
                    <a class="depth_1" href="javascript:void(0);" title="트립매거진">트립매거진</a>
                  </li>
                  <li @click="selectMenu(4)" :class="selectedMenu === 4 ? 'active' : ''">
                    <a class="depth_1" data-name="menu04" href="javascript:void(0);"
                      :title="siteName === '마이퍼스트가이드' ? '이벤트' : '커뮤니티'">{{ siteName === '마이퍼스트가이드' ? '이벤트' :
                        '커뮤니티' }}</a>
                  </li>
                  <li v-if="siteName === '도약닷컴' || siteName === '도약아트'" @click="selectMenu(5)"
                    :class="selectedMenu === 5 ? 'active' : ''">
                    <a class="depth_1" data-name="menu05" href="javascript:void(0);" title="튜터링">튜터링</a>
                  </li>
                  <li v-if="siteName === '도약닷컴' || siteName === '도약아트'" @click="selectMenu(6)"
                    :class="selectedMenu === 6 ? 'active' : ''">
                    <a class="depth_1" data-name="menu07" href="javascript:void(0);" title="자격증">자격증</a>
                  </li>
                </template>
                <!-- 과외 버전 트리 -->
                <template v-else>
                  <li @click="selectMenu(1)" :class="selectedMenu === 1 ? 'active' : ''">
                    <a class="depth_1" data-name="menu01" title="전문가">전문가</a>
                  </li>
                  <li @click="selectMenu(2)" :class="selectedMenu === 2 ? 'active' : ''">
                    <a class="depth_1" data-name="menu02" title="학생 요청서">학생 요청서</a>
                  </li>
                  <li v-if="siteName === '레슨올'" @click="selectMenu(3)" :class="selectedMenu === 3 ? 'active' : ''">
                    <a class="depth_1" data-name="menu03" title="연습실">연습실</a>
                  </li>
                </template>

                <li @click="selectMenu(8)" :class="selectedMenu === 8 ? 'active' : ''">
                  <a class="depth_1" title="고객센터">고객센터</a>
                </li>
                <li @click="selectMenu(9)" :class="selectedMenu === 9 ? 'active' : ''">
                  <a class="depth_1" title="마이페이지">마이페이지</a>
                </li>
                <li v-if="userStore.isCompanyIp" @click="selectMenu(50)" :class="selectedMenu === 50 ? 'active' : ''">
                  <a class="depth_1" title="설정(관리자)">설정(사내전용)</a>
                </li>
                <li v-if="siteName !== '레슨올'" @click="hamburger_cross">
                  <MiscDYLink class="depth_1" data-name="menu10" to="/search" title="검색하기"
                    style="color:#fff;font-size:16px;">
                    <v-icon icon="far fa-search-plus" /> 검색하기
                  </MiscDYLink>
                </li>
                <li v-if="userStore.isAndroidApp && siteName !== '레슨올'" style="margin-top:50px;"
                  @click="hamburger_cross">
                  <a href="#" class="btn btn-primary btn-lg" style="color:#fff;border-radius: 0;"
                    @click.prevent="openAndroidAppDownloadList">다운로드함</a>
                </li>
                <li v-if="userStore.isIOSApp && siteName !== '레슨올'" style="margin-top:50px;" @click="hamburger_cross">
                  <a href="#" class="btn btn-primary btn-lg" style="color:#fff;border-radius: 0;"
                    @click.prevent="openIOSAppDownloadList">다운로드함</a>
                </li>
                <li v-if="userStore.isAndroidApp" style="margin-top:10px;" @click="hamburger_cross">
                  <a href="#" class="btn btn-info btn-lg" style="color:#fff;border-radius: 0;"
                    @click.prevent="openAndroidAppSetting">앱 설정하기</a>
                </li>
                <li
                  v-if="(userStore.isAndroidOS && !userStore.isAndroidApp) || (userStore.isIOS && !userStore.isIOSApp)"
                  style="margin-top:10px;" @click="hamburger_cross">
                  <a v-if="userStore.isAndroidOS" :href="'market://details?id=' + ANDROID_APP_PACKAGE_NAME"
                    class="btn btn-info btn-lg" style="color:#fff;border-radius: 0;">앱 설치하기</a>
                  <template v-else-if="userStore.isIOS">
                    <a v-if="IOS_APP_STORE_ID" :href="`https://apps.apple.com/kr/app/id${IOS_APP_STORE_ID}`"
                      class="btn btn-info btn-lg" style="color:#fff;border-radius: 0;">앱 설치하기</a>
                  </template>
                  <a v-else :href="'https://play.google.com/store/apps/details?id=' + ANDROID_APP_PACKAGE_NAME"
                    class="btn btn-info btn-lg" style="color:#fff;border-radius: 0;">앱 설치하기</a>
                </li>
                <li v-if="userStore.isAndroidApp || userStore.isIOSApp" style="margin-top:10px;"
                  @click="hamburger_cross">
                  <a class="btn btn-lg bg-amber-600" style="color:#fff;border-radius: 0;" @click="refresh">새로고침</a>
                </li>
              </ol>

              <!-- //1Depth 메뉴트리 -->
              <ul class="depth_2_menu">
                <!-- 각 사이트별 핵심 메인이 되는 컨텐츠들. 인강의 경우엔 이 부분을 IGDefault에서 과외는 GW에서 선언 예정 -->
                <slot :click="hamburger_cross"></slot>
                <li v-if="selectedMenu === 8" class="active">
                  <ul class="depth_2">
                    <li>
                      <MiscDYLink :to="'/customerCenter'" @click="hamburger_cross">
                        고객센터
                        <v-icon icon="fal fa-chevron-right" class="topRightBtn fa-lg"></v-icon>
                      </MiscDYLink>
                      <ul class="depth_3">
                        <li @click="hamburger_cross" v-if="siteName !== '마이퍼스트가이드'">
                          <MiscDYLink :to="'/board/board_qna'">
                            1:1문의
                          </MiscDYLink>
                        </li>
                        <li @click="hamburger_cross" v-else>
                          <MiscDYLink :to="'/cs/contact'">
                            문의하기
                          </MiscDYLink>
                        </li>
                        <li @click="hamburger_cross">
                          <MiscDYLink :to="'/board/board_faq'">
                            자주 묻는 질문
                          </MiscDYLink>
                        </li>
                        <li @click="hamburger_cross">
                          <MiscDYLink :to="'/board/board_notice'">
                            공지사항
                          </MiscDYLink>
                        </li>
                        <!--          현재 마퍼가엔 필수 프로그램 다운받는 페이지 자체가 없음        -->
                        <li v-if="siteName === '도약닷컴' || siteName === '도약아트'" @click="hamburger_cross">
                          <MiscDYLink to="/customerCenter/down"> 필수 프로그램 </MiscDYLink>
                        </li>
                        <li v-if="siteName !== '레슨올'" @click="hamburger_cross">
                          <MiscDYLink to="/customerCenter/remoteService">
                            원격지원
                          </MiscDYLink>
                        </li>
                        <li @click="hamburger_cross">
                          <MiscDYLink :to="'/customerCenter/agree'">
                            이용약관
                          </MiscDYLink>
                        </li>
                        <li @click="hamburger_cross">
                          <MiscDYLink :to="'/customerCenter/privacy'">
                            개인정보처리방침
                          </MiscDYLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li v-if="selectedMenu === 9" class="active">
                  <ul class="depth_2">
                    <li>
                      <MiscDYLink :href="siteName === '레슨올' ? 'https://www.lessonall.com/mer/mypage/dashboard' : ''"
                        :to="siteName === '레슨올' ? '' : '/mypage'" @click="hamburger_cross">
                        {{ myPageName }}<v-icon icon="fal fa-chevron-right" class="topRightBtn fa-lg"></v-icon>
                      </MiscDYLink>
                      <!-- 인강일때의 마이페이지 하위메뉴 -->
                      <ul v-if="siteName !== '레슨올'" class="depth_3">
                        <li @click="hamburger_cross">
                          <MiscDYLink to="/mypage">
                            {{ myPageName }}
                          </MiscDYLink>
                        </li>
                        <li v-if="!userStore.isAndroidApp && siteName !== '레슨올'" @click="hamburger_cross">
                          <MiscDYLink to="/payment/cart">
                            장바구니
                          </MiscDYLink>
                        </li>
                        <li @click="hamburger_cross">
                          <MiscDYLink to="/mypage/recentChecked">
                            최근 본 상품
                          </MiscDYLink>
                        </li>
                        <li v-if="siteName === '도약닷컴' || siteName === '도약아트'" @click="hamburger_cross">
                          <MiscDYLink to="/cs/service/hugang">
                            휴강 신청/해제
                          </MiscDYLink>
                        </li>
                        <li v-if="siteName === '도약닷컴' || siteName === '도약아트'" @click="hamburger_cross">
                          <MiscDYLink to="/cs/service/extension">
                            연장 신청
                          </MiscDYLink>
                        </li>
                        <li v-if="siteName === '도약닷컴' || siteName === '도약아트'" @click="hamburger_cross">
                          <MiscDYLink to="/cs/service/re">
                            재수강 신청
                          </MiscDYLink>
                        </li>
                        <li v-if="siteName === '도약닷컴' || siteName === '도약아트'" @click="hamburger_cross">
                          <MiscDYLink to="/cs/notice/delivery">
                            배송조회
                          </MiscDYLink>
                        </li>
                      </ul>
                      <!-- 레슨올일 떄의 마이페이지 하위 메뉴 -->
                      <ul v-else class="depth_3">
                        <li @click="hamburger_cross">
                          <MiscDYLink href="https://www.lessonall.com/mer/mypage/requestFormList?formType=student">
                            내 요청서 등록/관리
                          </MiscDYLink>
                        </li>
                        <li @click="hamburger_cross">
                          <MiscDYLink href="https://www.lessonall.com/mer/mypage/requestFormList?formType=teacher">
                            내 전문분야 등록/관리
                          </MiscDYLink>
                        </li>
                        <li @click="hamburger_cross">
                          <MiscDYLink href="https://www.lessonall.com/mer/milk/scrapList">
                            내 스크랩 내역
                          </MiscDYLink>
                        </li>
                        <li @click="hamburger_cross">
                          <MiscDYLink href="https://www.lessonall.com/mer/milk/latelyReadingList">
                            내 최근 열람내역
                          </MiscDYLink>
                        </li>
                        <li @click="hamburger_cross">
                          <MiscDYLink to="/mypage/recentChecked">
                            최근 본 요청서
                          </MiscDYLink>
                        </li>
                        <li @click="hamburger_cross">
                          <MiscDYLink href="https://www.lessonall.com/renewal_gawe/08milk/payment_list.php">
                            내 결제 내역
                          </MiscDYLink>
                        </li>
                        <li @click="hamburger_cross">
                          <MiscDYLink href="https://www.lessonall.com/mer/milk/join02?modify=y">
                            내 정보 수정
                          </MiscDYLink>
                        </li>
                      </ul>
                    </li>
                    <li v-if="siteName !== '레슨올'" @click="hamburger_cross">
                      <MiscDYLink to="/mypage/messageBox">
                        메시지함
                      </MiscDYLink>
                    </li>
                    <li v-else @click="hamburger_cross">
                      <MiscDYLink
                        href="https://www.lessonall.com/mer/milk/messageBox?perPage=5&mode=all&checkOpenMessage=true">
                        메시지함
                      </MiscDYLink>
                      <ul class="depth_3">
                        <li @click="hamburger_cross">
                          <MiscDYLink
                            href="https://www.lessonall.com/mer/milk/messageBox?perPage=5&mode=receive&checkOpenMessage=true">
                            받은 메시지함
                          </MiscDYLink>
                        </li>
                        <li @click="hamburger_cross">
                          <MiscDYLink
                            href="https://www.lessonall.com/mer/milk/messageBox?perPage=5&mode=send&checkOpenMessage=true">
                            보낸 메시지함
                          </MiscDYLink>
                        </li>
                      </ul>
                    </li>
                    <li v-if="siteName !== '레슨올'" @click="hamburger_cross">
                      <MiscDYLink :to="'/mypage/coupon'">
                        쿠폰함
                      </MiscDYLink>
                    </li>
                    <li v-if="userStore.isSignedIn">
                      <a href="javascript:;">회원정보<v-icon icon="fal fa-chevron-right"
                          class="topRightBtn fa-lg"></v-icon></a>
                      <ul class="depth_3">
                        <li @click="hamburger_cross">
                          <MiscDYLink
                            :href="siteName === '레슨올' ? 'https://www.lessonall.com/mer/milk/join02?modify=y' : ''"
                            :to="siteName === '레슨올' ? '' : '/join/modify'">정보수정</MiscDYLink>
                        </li>
                        <li v-if="siteName === '도약닷컴' || siteName === '도약아트'" @click="hamburger_cross">
                          <MiscDYLink to="/mypage/membership"> 나의 회원 등급 </MiscDYLink>
                        </li>

                        <!-- 현재 애플 앱용 계정 연동 기능이 개발되지 않아서 있는 코드. 개발되기 전까진 웹에서 해야만 함 -->
                        <li v-if="!userStore.isIOSApp && siteName !== '레슨올'" @click="hamburger_cross">
                          <MiscDYLink to="/mypage/connectSocialLogin">로그인 연동</MiscDYLink>
                        </li>
                        <li @click="hamburger_cross">
                          <DYLogoutLink />
                        </li>
                        <li @click="hamburger_cross">
                          <MiscDYLink :to="'/mypage/delmember'">
                            회원탈퇴
                          </MiscDYLink>
                        </li>
                      </ul>
                    </li>
                    <li v-if="siteName !== '레슨올'">
                      <a href="javascript:;">상세내역보기<v-icon icon="fal fa-chevron-right"
                          class="topRightBtn fa-lg"></v-icon></a>
                      <ul class="depth_3">
                        <li @click="hamburger_cross">
                          <MiscDYLink to="/mypage/payment">
                            결제 내역
                          </MiscDYLink>
                        </li>
                        <li @click="hamburger_cross">
                          <MiscDYLink to="/mypage/point">
                            포인트 내역
                          </MiscDYLink>
                        </li>
                        <li @click="hamburger_cross">
                          <MiscDYLink to="/mypage/cash">
                            별캐시 내역
                          </MiscDYLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a v-if="userStore.isAndroidApp"
                        @click="userStore.isAndroidApp ? 'window.android.openSetting()' : 'location.href=/m/setting_ax.php'">앱
                        설정하기</a>
                    </li>
                  </ul>
                </li>
                <li v-if="selectedMenu === 50" class="active">
                  <ul class="depth_2">
                    <li>
                      <MiscDYLink :to="'/customerCenter'" @click="hamburger_cross">
                        디스플레이
                        <v-icon icon="fal fa-chevron-right" class="topRightBtn fa-lg"></v-icon>
                      </MiscDYLink>
                      <ul class="depth_3">
                        <li>다크모드
                          <v-switch v-model="isDark" color="primary"></v-switch>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
          <div class="overlay toggleHamburger" @click="hamburger_cross" />
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script setup lang="ts">
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight, faCog, faMailBulk, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faSearchPlus } from '@fortawesome/pro-regular-svg-icons';
import type { WatchStopHandle } from 'vue';
import { $dy__useDYMainStore } from '~/utils';
import { useDYComponent } from '~/modules/nuxt3-module-doyac/composables/dy-component';
import { useDYMessageApi } from '~/modules/nuxt3-module-doyac/composables/dy-message';
import { useDYLayoutStore } from '~/modules/nuxt3-module-doyac/stores/layout';
import {useUtils} from "~/modules/nuxt3-module-doyac/composables/utils";
import {useDark, useToggle} from "@vueuse/core";
import {isCompanyIp} from "~/modules/nuxt3-module-doyac/composables/common";


library.add(faSearchPlus, faCog, faMailBulk, faTimes, faChevronRight)

const router = useRouter()
const route = useRoute()
const userStore = $dy__useDYUserStore()
const mainStore = $dy__useDYMainStore()
const { siteName, ANDROID_APP_PACKAGE_NAME, IOS_APP_STORE_ID, fullDomain } = useAppConfig()
const layoutStore = useDYLayoutStore()
const { getList } = useDYMessageApi()
const dyComponent = useDYComponent()
const { notifyError } = useUtils()

const props = withDefaults(defineProps<{
  selectedMenu: number
  cashName?: string
  shopName?: string
}>(), {
  selectedMenu: () => 1,
  cashName: () => '',
  shopName: () => '',
})

const emit = defineEmits<{
  (e: 'update:selectedMenu', v: number): void
}>()

const open = ref(false)

const myPageName = computed(() => {
  switch (siteName) {
    case '도약닷컴': return '내 강의실'
    case '도약아트': return '내 강의실'
    case '마이퍼스트가이드': return '내 투어/클래스'
    case '레슨올': return '마이 페이지'
  }
})

const hamburger_cross = () => {
  // layoutStore.isRightPannelVisible = !layoutStore.isRightPannelVisible
  open.value = !open.value
  // emit('update:isOpened', false)
}

const isDark = useDark()
useToggle(isDark)

const refresh = () => {
  location.reload()
}

const selectMenu = (val: number) => {
  emit('update:selectedMenu', val)
}

const openAndroidAppSetting = () => {
  if (userStore.isAndroidApp) {
    //@ts-ignore
    window.android.openSetting()
  } else {
    location.href = `market://details?id=${ANDROID_APP_PACKAGE_NAME}`
  }
}

const openAndroidAppDownloadList = () => {
  if (userStore.isAndroidApp) {
    //@ts-ignore
    window.android.openDownloadList()
  } else {
    location.href = `market://details?id=${ANDROID_APP_PACKAGE_NAME}`
  }
}

const openIOSAppDownloadList = () => {
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.scriptHandler) {
    window.webkit.messageHandlers.scriptHandler?.postMessage({
      script: 'openDownloadManager',
      params: []
    })
  }
}

const toHome = () => {
  hamburger_cross()
  router.push('/')
}

onMounted(async () => {
  await nextTick()

  watch(() => userStore.isSignedIn, (val) => {

      if (val) { //로그인 하는 순간에 체크되도록
        setTimeout(async () => { //셋 타임을 안 걸면 요청을 제대로 못함. 이 컴포넌트가 레이아웃 단계에서 불려서 그런듯
          try {
            const res = await getList({ my: 'y', visibleOnWeb: 1, opened: 0, untilToday: true })
            userStore.countUnreadMessage = res.totalCount
          } catch (e: any) {
            console.error('DYRightPanel', e)
            notifyError(e)
          }
        }, 1000)
      } else { // 로그아웃 하면 unreadMessage 초기화
        userStore.countUnreadMessage = 0
      }


  })
})

if (process.client) {
  // 2023.11.13 기준 swiper.js를 VNavigationDrawer와 함께 사용하면 터치 관련 에러가 발생한다.
  // 따라서 함께 쓰이지 않게 하기 위해 우상단 메뉴 버튼을 눌렀을 때 VNavigationDrawer가 만들어지는 방식을 써야한다.
  // 다만 이렇게 하면 VNavigationDrawer에 트랜지션 효과가 전혀 적용되지 않으므로 다음의 방식을 따른다.
  // 1. 우상단 메뉴 버튼 클릭 시 `layoutStore.isRightPannelVisible` 값을 변경
  // 2. `layoutStore.isRightPannelVisible`이 변경되면 그에 맞춰 `VNavigationDrawer` 생성
  // 3. `VNavigationDrawer`가 생생될 수 있는 아주 짧은 시간적 여유를 주고 `VNavigationDrawer`이 열리도록 변경
  watch(() => layoutStore.isRightPannelVisible, (value) => {
    nextTick(() => {
      // 타임아웃을 줘야 트랜지션이 적용되므로 주의!!
      // setTimeout(() => {
      open.value = value
      // }, 10)
    })
  })
}

let unwatchIsLoading: WatchStopHandle | undefined

if (process.client) {
  // `open`의 상태를 감시하여 `layoutStore.isRightPannelVisible`를 동기화하기 위한 코드. 동기화시키지 않으면 한 번 패널이
  // 열린 이후에 다시 패널을 열려고 할 때 바로 열리지 못하고 두 번 클릭해야 열리는 문제가 발생한다. 동기화 작업에는 몇가지
  // 주의할 점이 존재함.
  // 1. `open`이 `false`로 바뀌는 시점에 바로 `layoutStore.isRightPannelVisible`을 동기화하면 트랜지션 효과가 적용되지
  // 못함. 따라서 약간의 시간차를 두고 변경해야함.
  // 2. 페이지가 이동 중인 경우에는 `layoutStore.isRightPannelVisible`이 변경되면 Vue.js 자체에 치명적인 버그가 발생함.
  // (Uncaught (in promise) TypeError: Cannot read properties of null (reading 'parentNode'))
  // 아마도 페이지 생성 도중에 레이아웃의 DOM이 변경되면 발생되는 에러로 추정됨. 따라서 페이지 이동이 완료된 이후에
  // 동기화시켜야함.
  watch(open, (value) => {
    if (value !== layoutStore.isRightPannelVisible) {
      // setTimeout(() => {
      layoutStore.isRightPannelVisible = value
      // }, 100)
      // 페이지가 이동 중일 때 발생한 경우. 링크 클릭이 발생하여 우측 패널이 자동으로 닫히는 경우가 이에 해당함.
      if (mainStore.isLoading) {
        // 페이지 이동이 종료되는 것을 감시하다가 종료되는 시점에 동기화. 이 경우는 충분한 시간차가 발생한 이후이므로
        // 별도의 대기 시간을 주지 않아도 트랜지션이 적용된다.
        unwatchIsLoading = watch(() => mainStore.isLoading, (isLoading) => {
          if (!isLoading) {
            layoutStore.isRightPannelVisible = value
            if (unwatchIsLoading) {
              unwatchIsLoading()
              unwatchIsLoading = undefined
            }
          }
        })
      }
      // 페이지 이동 없이 발생한 경우. 링크 클릭 없이 그냥 우측 패널을 닫았을 때 발생함.
      else {
        // 곧바로 동기화시키면 트랜지션이 작동하지 않으므로 0.1초의 시간차를 두고 동기화시킴
        setTimeout(() => {
          layoutStore.isRightPannelVisible = value
        }, 100)
      }
    }
  })

  watch(() => route.path, (path) => {
    if (path === '/mypage/messageBox') {
      userStore.countUnreadMessage = 0
    }
  })
}

</script>

<style scoped>
aside {
  position: fixed;
  width: 100%;
  z-index: 1500;
}

aside.on:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 10000;
}

.gnb {
  position: fixed;
  /*right:-100%!important;*/
  top: 0;
  width: 100%;
  height: 100%;
  padding-top: 55px;
  box-sizing: border-box;
  background: #fff;
  z-index: 10001;
  transition: right .5s ease-in;
  border-left: 1px solid #ececec;
}

aside.on .gnb {
  right: 0;
}

@media (min-width: 450px) {
  .gnb {
    width: 450px;
  }
}

nav div ul li a {
  font-weight: 500;
}

/* 최근접속 수정 2019-09-30 */
.gnb .top_btn {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 55px;
  border-bottom: 1px solid #e0e0e0;
  box-sizing: border-box;
  background: #fff;
  text-align: right;
  overflow: hidden;
}

.gnb .top_btn li {
  display: inline-block;
  vertical-align: top;
}

.gnb .top_btn li a {
  display: block;
  /* width: 34px; */
  height: 55px;
  text-align: left;
  /*text-indent:-9999px;*/
  color: transparent;
  overflow: hidden;
}

/* .gnb .top_btn li.login {
  float: left;
} */

.gnb .top_btn li.date {
  float: left;
  padding: 23px 0;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: -0.7px;
  text-align: left;
  color: #222;
  background: #fff;
}

.gnb .top_btn li.date span {
  display: inline-block;
  color: #666;
}

.gnb .top_btn .btn_login,
.gnb .top_btn .btn_search,
.gnb .top_btn .btn_mailbox,
.gnb .top_btn .btn_home,
.gnb .top_btn .btn_close {
  color: #393939;
  margin-top: 15px;
  margin-right: 4px;
  padding-right: 6px;
  font-size: 25px;
}

.gnb .top_btn .btn_login {
  width: auto;
  padding: 0 5px 0 25px;
  color: #393939;
  font-weight: bold;
  font-size: 15px;
  line-height: 28px;
  text-indent: 0;
}

.gnb .top_btn i {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: bottom;
}

.gnb .top_btn:deep(svg) {
  height: 25px;
}

.gnb .top_btn .btn_home {}

.gnb .scroll_box {
  height: 100%;
  padding-left: 38%;
  position: relative;
}

.gnb .scroll_box>ol {
  width: 38%;
  height: 100%;
  padding: 15px 0;
  padding-bottom: 50px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  background: #222;
  overflow: hidden;
  overflow-y: auto;
}

.gnb .scroll_box>ol li a {
  padding: 10px 10px 10px 15px;
  display: block;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -1px;
  color: #989ca7;
}

.gnb .scroll_box>ol li a:hover,
.gnb .scroll_box>ol li a:focus {
  color: #fff;
}

.gnb .scroll_box>ol li.active {
  position: relative;
}

.gnb .scroll_box>ol li.active:after {
  content: "";
  display: block;
  position: absolute;
  right: 0px;
  top: 50%;
  width: 14px;
  height: 3px;
  margin-top: -2px;
  background: #00907f;
}

.gnb .scroll_box>ol li.active a {
  font-weight: bold;
  color: #fff;
}

.gnb .scroll_box>ol li.branch a {
  font-weight: bold;
  color: #e3d8ae;
  font-size: 17px;
  padding: 15px 10px 15px 25px;
  margin-top: 40px;
  margin-bottom: 20px;
  background: #545c69;
}

.gnb .scroll_box>ol li.branch a:hover,
.gnb .scroll_box>ol li.branch a:focus {
  color: #fff;
}

.gnb .scroll_box>ol li.branch.active {
  position: relative;
}

.gnb .scroll_box>ol li.branch.active:after {
  content: "";
  display: block;
  position: absolute;
}

.gnb .scroll_box>ul {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background: #eee;
}

.gnb .scroll_box>ul>li {
  padding: 0 12px;
  padding-bottom: 50px;
  display: none;
  min-height: 100%;
  box-sizing: border-box;
}

.gnb .scroll_box>ul>li.active {
  display: block;
}

.gnb .scroll_box>ul>li h2 {
  position: absolute;
  top: -9999px;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.gnb .scroll_box .depth_2 {
  padding: 10px 0;
}

.gnb .scroll_box .depth_2>li>a {
  display: block;
  position: relative;
  padding: 15px 15px 15px 0;
  border-bottom: 1px solid #e0e0e0;
  box-sizing: border-box;
  color: #00907f;
  font-weight: bold;
  font-size: 14px;
}

.gnb .scroll_box .depth_2>li:first-child>a {
  padding-top: 15px;
}

.gnb .scroll_box .depth_2 .topRightBtn {
  display: block;
  position: absolute;
  top: 21px;
  right: 0;
  width: 6px;
  height: 10px;
  float: right;
  font-size: 13px;
}

.gnb .scroll_box .depth_2>li:first-child>a[href]:after {
  top: 23px;
}

.ios .gnb .scroll_box .depth_2>li>a[href]:after {
  top: 28px;
}

.gnb .scroll_box .depth_3 {
  padding-top: 10px;
}

.gnb .scroll_box .depth_3>li>a {
  padding: 6px 0 9px 6px;
  display: block;
  position: relative;
  font-size: 14px;
  color: #222;
}

.gnb .scroll_box .depth_3>li.toggle>a {
  padding-right: 20px;
}

.gnb .scroll_box .depth_3 .plusBtnDepth3 {
  font-size: 14px;
  display: block;
  position: absolute;
  top: 12px;
  right: 0;
  width: 14px;
  height: 14px;
  color: #00907f;
}

.gnb .scroll_box .depth_3>li.toggle.active>a {
  font-weight: bold;
}

.gnb .scroll_box .depth_4 {
  display: none;
  padding-left: 6px;
}

.gnb .scroll_box .depth_3>li.toggle.active .depth_4 {
  display: block;
}

.gnb .scroll_box .depth_4>li>a {
  display: block;
  position: relative;
  padding: 10px 0 0 12px;
  margin-top: -3px;
  height: 35px;
  line-height: 13px;
  color: #909090;
  font-size: 13px;
}

.gnb .scroll_box .depth_4>li>a:before,
.gnb .scroll_box .depth_4>li>a:after {
  content: "";
  display: block;
  position: absolute;
  top: 13px;
  left: 0;
  width: 1px;
  height: 5px;
  background: #b2b2b2;
}

.gnb .scroll_box .depth_4>li>a:after {
  top: 18px;
  width: 5px;
  height: 1px;
}

@media (min-width: 450px) {
  .gnb {
    width: 400px;
  }

  .gnb .scroll_box>ol li a {
    padding: 15px 10px 15px 15px;
    font-size: 16px;
  }

  .gnb .scroll_box .depth_2>li>a {
    font-size: 16px;
  }

  .gnb .scroll_box .depth_3>li>a {
    font-size: 15px;
  }

  .gnb .scroll_box .depth_4>li>a {
    font-size: 14px;
  }
}

.gnb .top_btn .login .sc_psn_stt {
  overflow: hidden;
  height: 50px;
  /* width: 200px; */
}

.gnb .top_btn .login .sc_psn_stt .sc_psn_a {
  /* width: 200px; */
  overflow: hidden;
  position: relative;
  display: block;
  margin: 7px 0 0 12px;
  padding: 3px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.gnb .top_btn .login .sc_psn_stt .sc_psn_a:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 38px;
  height: 38px;
  background-position: -202px -64px
}

.gnb .top_btn .login .sc_psn_stt .sc_psn_a .userNickName {
  font-size: 16px;
  font-weight: bold;
  line-height: 32px;
  padding-right: 32px;
  color: #5f5f5f;
}

.gnb .top_btn .login .sc_psn_stt .dy-img {
  position: relative;
  z-index: 10;
  margin-right: 10px;
  border-radius: 50%;
}

@media (min-height: 500px) {
  /*.gnb {padding-top:100px;}
  .gnb .top_btn  {height: 100px;}*/
}

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(250, 250, 250, .8);
  z-index: 990;
}

#openPanel {
  z-index: 1101;
  right: -100%;
  left: auto;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #1a1a1a;
  /*-webkit-transition: all 0.5s ease; -moz-transition: all 0.5s ease; -o-transition: all 0.5s ease; transition: all 0.5s ease;*/
}

#openPanel.is-closed,
#openPanel.is-open {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#openPanel.is-closed:before {
  content: '';
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.35s ease-in-out;
}

#openPanel.is-open {
  left: auto;
  right: 0;
  z-index: 1101;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.vuetify3:deep(.v-navigation-drawer__content) {
  display: contents;
}
</style>